import { catalogRouteNames } from '@/router/constants';
import { getNavigationGuard } from '@/router/RouterHooks/bindNavigation';
import { hasAuthCookie, isPrivateCatalog } from '@/router/RouterHooks/constants';

let navigationGuard = {};
/*
  Если подобные проверки начнут разрастаться переделать на meta свойства роута
*/
const useForAuthorizedUserRoute = () => {
  navigationGuard = getNavigationGuard();

  hasAuthCookie() || isPrivateCatalog
    ? navigationGuard.routeNext()
    : redirectCatalogList();
};

const redirectCatalogList = () => {
  navigationGuard.routeNext({ name: catalogRouteNames.MAIN_CATALOG });
};

export default useForAuthorizedUserRoute;
