import ax from 'axios';
import vars from 'assets/utils/vars';

const interfaceLanguage = vars.settings.interface_language;
const { TOKEN, lang, HOST } = vars;

const defaultAxiosConfig = () => ax.create({
  baseURL: HOST + '/api/public/v1/',
  headers: {
    'TOKEN': TOKEN,
    'LOCALE': lang,
  },
});

/*
  Для отправки писем на языке интерфейса
 */
const registrationUserAxiosConfig = () => ax.create({
  baseURL: HOST + '/api/public/v1/',
  headers: {
    'TOKEN': TOKEN,
    'LOCALE': interfaceLanguage,
  },
});

export { defaultAxiosConfig, registrationUserAxiosConfig };
