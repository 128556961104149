import initialVars from 'assets/utils/vars';
import authTypesConst from 'assets/utils/constants/authTypes';
import cookie from 'js-cookie';

const { settings: { auth_type: authType, is_private: isPrivateCatalog }, cookie_name: cookieKeyNameToken } = initialVars;

const isRequiredAuth = authType === authTypesConst.REQUIRED;
const isAuthNotNeeded = authType === authTypesConst.NO;
const hasAuthCookie = () => cookie.get(cookieKeyNameToken);

export { isRequiredAuth, hasAuthCookie, isPrivateCatalog, isAuthNotNeeded };
