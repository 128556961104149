import { getNavigationGuard } from '@/router/RouterHooks/bindNavigation';
import urls from '@modules/Auth/urls';
import getDataHelper from 'assets/utils/getDataHelper';
import { authRouterNames } from '@/router/constants';

let GET_DATA = getDataHelper(null, urls);
let navigationGuard = {};

const useActivateUser = async () => {
  navigationGuard = getNavigationGuard();
  await GET_DATA({ url: 'activateUser', query: { token: navigationGuard.routeTo.query.token } });
  navigationGuard.routeNext({ name: authRouterNames.AUTHORIZATION_PUBLIC_AUTH });
};

export default useActivateUser;
