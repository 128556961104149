let navigationGuard = {
  routeTo: {},
  routeNext: () => {},
};

const bindingNavigationGuard = ({ to, next }) => {
  navigationGuard = {
    routeTo: to,
    routeNext: next,
  };
};

const getNavigationGuard = () => {
  return navigationGuard;
};

export { bindingNavigationGuard, getNavigationGuard };
