import meta from 'assets/mixins/meta';
import setRouterQuery from 'assets/mixins/setRouterQuery';
import Icon from 'UI/Icon';
import Modal from 'bq/Modal';
import openModals from 'assets/mixins/modals';
import Button from 'UI/Button';
import Dropdown from 'bq/Dropdown';
import FadeOut from 'bq/Animations/FadeOut';
import clickOutside from 'assets/directives/click-outside';
import authorization from 'assets/mixins/authorization';

export function globalComponents(Vue) {
  Vue.prototype.$t = window.$translate;
  Vue.mixin(meta);
  Vue.mixin(setRouterQuery);
  Vue.mixin(openModals);
  Vue.mixin(authorization);
  Vue.component('b-icon', Icon);
  Vue.component('b-button', Button);
  Vue.component('b-dropdown', Dropdown);
  Vue.component('Modal', Modal);
  Vue.component('FadeOut', FadeOut);
  Vue.directive('click-outside', clickOutside);
}
