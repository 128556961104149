import initialVars from 'assets/utils/vars';

export default () => {
  const paths = initialVars.root_paths;
  const url = getLocation(window.location.href).pathname;

  let [ matched ] = paths.filter(e => url.search(e) !== -1);

  return matched ? `/${matched}/` : '/';
};

const getLocation = href => {
  let location = document.createElement('a');
  location.href = href;
  return location;
};
