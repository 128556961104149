import Vue from 'vue';
import VueRouter from 'vue-router';
import MainCatalog from './Pages/MainCatalog';
import Authorization from './Pages/Authorization';
import { isEqual } from 'lodash-es';
import mapRouterHooks from '@/router/RouterHooks';
Vue.use(VueRouter);

export function createRouter() {
  const routes = [
    ...Authorization,
    ...MainCatalog,
  ];

  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
      // скроллим, только если поменялся маршрут или поменялись параметры(но не query)
      if (to.name !== from.name || !isEqual(to.params, from.params)) {
        return savedPosition || { x: 0, y: 0 };
      }
    },
  });
  router.beforeEach((to, from, next) => {
    mapRouterHooks.GLOBAL({ to, from, next });
  });

  return router;
}
