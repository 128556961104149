export default {

  methods: {
    SET_TITLE(title = null) {
      if (title) {
        if (typeof window !== 'undefined') {
          document.title = title;
        } else {
          this.$ssrContext.title = title;
        }
      }
    },
    SET_META(metaObj) {
      if (typeof window !== 'undefined') {
        for (let key in metaObj) {
          let metaItem = document.querySelector(`meta[name="${key}"]`);
          if (metaItem) {
            metaItem.content = metaObj[key];
          }
        }
        document.title = metaObj.title;
      } else {
        this.$ssrContext.description = metaObj.description;
        this.$ssrContext.keywords = metaObj.keywords;
        this.$ssrContext.title = metaObj.title;
      }
    },
  },
};
