/**
 * @param {Array,String} modulePathArray - пути до модуля/подмодуля. Для регистрации подмодуля нужно использовать только Array
 * @param {StoreModule} storeModule - объект регистрируемого модуля store
 * @param {Boolean} needDestroy - нужно ли удалять состояние модуля при уходе с маршрута
 */
export default (modulePathArray, storeModule, needDestroy = true) => {
  // переделываем строку до модуля в Array для избежания недоразумений при регистрации
  let arrPathToModule = modulePathArray;
  if (typeof modulePathArray === 'string')arrPathToModule = modulePathArray.split('/');

  return {
    serverPrefetch() {
      return this.registerModule();
    },
    created() {
      // в хуке created только на клиенте
      if (typeof window !== 'undefined') {
        this.registerModule();
      }
    },
    beforeDestroy() {
      if (needDestroy && checkModule(arrPathToModule, this.$store)) {
        this.$store.unregisterModule(arrPathToModule);
      }
    },
    methods: {
      async registerModule() {
        const moduleExist = checkModule(arrPathToModule, this.$store);
        if (!moduleExist) {
          await this.$store.registerModule(arrPathToModule, storeModule, { preserveState: !!moduleExist });
        }
      },
    },
  };
};
/**
 * Проверяет, существует ли состояние для регистрируемого модуля или нет
 * @param {Array,String} pathToModule Путь до модуля
 * @param {Store} state
 */
function checkModule(pathToModule, store) {
  let resState = store.state;
  pathToModule.forEach(path => {
    if (resState !== undefined) {
      resState = resState[path];
    } else {
      console.error('Не правильно указан путь регистрации до динамического модуля store');
    }
  });
  return resState;
}
