import title from '../setTitle.js';
import getBaseUrl from 'assets/utils/getBaseUrl';
import mapRouterHooks from '@/router/RouterHooks';
import { authRouterNames } from '@/router/constants';
const t = window.$translate.public_catalog_v2.app_components;

export default [
  {
    name: authRouterNames.AUTHORIZATION,
    path: `${getBaseUrl()}authorization`,
    redirect: { name: authRouterNames.AUTHORIZATION_PUBLIC_AUTH },
    components: {
      default: () => import(/* webpackChunkName: "authorization" */'@pages/OtherPages/authorization'),
    },
    children: [
      {
        name: authRouterNames.AUTHORIZATION_PUBLIC_AUTH,
        path: 'auth',
        components: {
          default: () => import(/* webpackChunkName: "PublicAuth" */'@pages/OtherPages/authorization/Pages/PublicAuth'),
        },
        meta: {
          title: title(t.authorization),
        },
        beforeEnter(to, from, next) {
          mapRouterHooks.PUBLIC_AUTH({ to, from, next });
        },
      },
      {
        name: authRouterNames.AUTHORIZATION_USER_ACTIVATE,
        path: 'activate_user',
        async beforeEnter(to, from, next) {
          await mapRouterHooks.ACTIVATE_USER({ to, from, next });
        },
      },
    ],
  },
];
