import { bindingNavigationGuard } from '@/router/RouterHooks/bindNavigation';
import useActivateUser from '@/router/RouterHooks/Hooks/useActivateUser';
import useGlobalBeforeRoute from '@/router/RouterHooks/Hooks/useGlobalRoute';
import usePublicAuth from '@/router/RouterHooks/Hooks/usePublicAuth';
import useForAuthorizedUserRoute from '@/router/RouterHooks/Hooks/useForAuthorizedUserRoute';

const mapRouterHooks = {
  GLOBAL: (navigationGuard) => preparingTheCall(navigationGuard, useGlobalBeforeRoute),
  PUBLIC_AUTH: (navigationGuard) => preparingTheCall(navigationGuard, usePublicAuth),
  ACTIVATE_USER: (navigationGuard) => preparingTheCall(navigationGuard, useActivateUser),
  FOR_AUTHORIZED: (navigationGuard) => preparingTheCall(navigationGuard, useForAuthorizedUserRoute),
};

const preparingTheCall = (navigationGuard, hook) => {
  bindingNavigationGuard(navigationGuard);
  hook();
};

export default mapRouterHooks;
