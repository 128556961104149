import urls from './modules/MainCatalog/urls';
import getDataHelper from 'assets/utils/getDataHelper';
let GET_DATA = getDataHelper(null, urls);

export default {
  async TOGGLE_BASKET({ state, commit, rootState }, params) {
    let url = params.select ? 'addInBasket' : 'removeFromBasket';
    let res = await GET_DATA(url, 'post', { item_id: params.id });
    if (res.success) {
      commit('SET_COUNT_BASKET', res.data.count);
    }
    return res;
  },
};
