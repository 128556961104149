import generateUrlConstructor from 'assets/utils/generateUrl';
import { defaultAxiosConfig } from 'assets/utils/getDataHelper/axiosInstanses';

const axios = defaultAxiosConfig();
axios.interceptors.request.use(
  (config) => {
    if (config.headers.TOKEN !== window.__INITIAL__DJANGO__.TOKEN) {
      config.headers.TOKEN = window.__INITIAL__DJANGO__.TOKEN;
    }

    return config;
  },
  error => Promise.reject(error)
);
/**
 * Хелпер для запросов, который всегда возвращает объект БЕЗ выбрасывания catch
 * Может принимать в качестве аргумента конструктора объект ajax
 * (если вам нужно модифицировать что-то)
 *
 *
 * @param {Object} - ajax подобный объект
 * @param {Object} - объект с константами url
 * @return {Function} функцию для запросов на сервер
 */
export default function (axiosObject = null, constantsObject = null) {
  const AJAX = axiosObject || axios;
  const generateUrl = generateUrlConstructor(constantsObject);

  /**
  *функция принимает название константы или параметр урла,
  * н и параметры запроса
  * возвращает готовый url
  * @param {String,Object} urlObj - название константы или объект для формирования урла
  * @param {Object} settings - параметры запроса или строка с методом запроса
  * @param {Object} data - данные которые отправляем на сервер
  * @return {Promise} промис с запросом к серверу
  **/
  return async function (urlObj, settings = 'GET', data = {}) {
    let url = null;
    let params = null;
    let query = null;
    // если нам скидывают урл или константу
    if (typeof urlObj === 'string') {
      // если это константа
      if (urlObj.indexOf('/') === -1) url = generateUrl(urlObj);
      // если сразу ссылка
      else url = urlObj;
    } else if (typeof urlObj === 'object') {
      // если надо сгенерировать
      url = urlObj.url;
      params = urlObj.params || null;
      query = urlObj.query || null;
      url = generateUrl(url, { params, query });
    }

    // если вторым параметром приходит строка
    if (typeof settings === 'string') {
      settings = {
        method: settings,
      };
    }

    let res = null;
    let resEnd = { success: false, data: null };
    try {
      // тут хаки к прошлой реализации ajax
      let sendData = calcOptions({
        url: url,
        data: data,
        ...settings,
      });
      res = await AJAX(sendData);
      resEnd = { success: true, data: res.data };
    } catch (e) {
      resEnd = { ...resEnd, ...hendlerError(e) };
    }
    return resEnd;
  };
}

/**
 * функция обработки ошибки с сервера и возврат в стандартном виде(на бекенде нет одного стандарта)
 *
 * @param {Response Error}
 * @return {Object} {error:String,errors:Object,code:500 || response.status}
 */
function hendlerError(error) {
  let res = { error: null, errors: {}, code: 500 };
  let response = error.response;
  if (typeof response === 'object') {
    res.code = response.status;
    // если на верхнем уровне респонса есть проперти status
    if (response.status !== undefined) {
      res.errors = response.data || response.errors;
      if (response.status >= 500) {
        res.error = 'Ошибка на сервере';// window.$translate.errors.error5xx;
      }
    } else if (response.errors !== undefined) {
      res.errors = response.errors;
    } else if (response.message) {
      res.error = response.message;
    } else {
      res.errors = response;
    }
  } else if (typeof response === 'undefined') {
    res.error = 'Ошибка на сервере';
  }
  return res;
}

function calcOptions(options = {}) {
  let axiosDefaultOpt = {
    dataType: 'json',
  };

  if (options.method.toLowerCase() !== 'get') {
    if (options.data && Object.keys(options.data).length === 0 && typeof options.data.append === 'function') { // if send FormData
      axiosDefaultOpt.headers = { 'Content-Type': 'multipart/form-data' };
    }
  }
  axiosDefaultOpt = { ...axiosDefaultOpt, ...options };
  return axiosDefaultOpt;
}
