import { authRouterNames } from '@/router/constants';
import { getNavigationGuard } from '@/router/RouterHooks/bindNavigation';
import { hasAuthCookie, isRequiredAuth } from '@/router/RouterHooks/constants';

let navigationGuard = {};

const useGlobalBeforeRoute = () => {
  navigationGuard = getNavigationGuard();
  isGoingForAuthorization() ? navigationGuard.routeNext() : accessVerificationPK2();
};

const accessVerificationPK2 = () => {
  isRequiredAuth && !hasAuthCookie() ? redirectPublicAuthRoute() : navigationGuard.routeNext();
};

const redirectPublicAuthRoute = () => {
  navigationGuard.routeNext({ name: authRouterNames.AUTHORIZATION_PUBLIC_AUTH, query: { to: getFullPath() } });
};
const getFullPath = () => navigationGuard.routeTo.fullPath;

const isGoingForAuthorization = () => {
  return [authRouterNames.AUTHORIZATION_PUBLIC_AUTH, authRouterNames.AUTHORIZATION_USER_ACTIVATE].includes(navigationGuard.routeTo.name);
};

export default useGlobalBeforeRoute;
