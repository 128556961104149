
/**
  * Конструктор принимает объект констант урлов
  *функция принимает название константы и параметры для замены
  * возвращает готовый url
  * @param {String} constant - название константы
  * @param {Object} params - парамтры для замены
  * @return {String} готовая строка с урлом
  **/
export default function (constantsObject = null) {
  let urls = {};
  if (constantsObject !== null) {
    urls = constantsObject;
  }

  return function (constant, myReplace = {}) {
    let result = urls[constant] || null;
    if (result && Object.keys(myReplace).length > 0) { // сначала меняем переопределённые свойства
      let params = myReplace.params || {};
      let query = myReplace.query || {};
      // сначала меняем значения в строке
      for (let param in params) {
        result = result.replace(':' + param, params[param]);
      }
      // в джанге стоит мод на последний слеш(обязателен последний слеш в урле)
      if (result[result.length - 1] !== '/')result += '/';

      // потом если query есть
      if (Object.keys(query).length > 0) {
        let addQueryString = [];
        for (let param in query) {
          if (query[param])addQueryString.push(param + '=' + encodeURIComponent(query[param]));
        }
        if (addQueryString.length > 0) {
          result += result.includes('?') ? '&' : '?';
          result += addQueryString.join('&');
        }
      }
    }
    return result;
  };
}
