<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import componentWithDynamicModule from 'assets/extends/componentWithDynamicModule';
import ModuleStore from '@modules/Auth';

import authTypes from 'assets/utils/constants/authTypes';
import vars from 'assets/utils/vars';

function needAuth() {
  return vars.settings.auth_type === authTypes.NO;
}

export default {
  name: 'Main',

  mixins: !needAuth() ? [componentWithDynamicModule('Auth', ModuleStore, false)] : [],

  computed: {
    ...mapState(['isAuthorized']),
  },

  watch: {
    // установка meta заголовков
    '$route': {
      immediate: true,
      handler(to) {
        /**
         * возможно потом понадобится переделать с просто meta маршрута на
         * выборку первого элемента из mathed, имеющего meta
         */
        if (to.meta && to.meta.title) {
          this.SET_META(to.meta);
        }
      },
    },
  },

  created() {
    if (!this.isAuthorized) {
      this.SET_AUTHORIZED_STATUS();
    }
  },

  mounted() {
    this.SET_WINDOW_WIDTH(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
      this.SET_WINDOW_WIDTH(document.documentElement.clientWidth);
    });
  },

  methods: {
    ...mapMutations([
      'SET_WINDOW_WIDTH',
      'SET_AUTHORIZED_STATUS',
    ]),
  },
};
</script>

<style lang="stylus">
html {
  box-sizing: border-box;
  font-family 'IBM Plex Sans', sans-serif
  color $colors.grey.base
}
*, *:before, *:after {
  box-sizing: inherit;
}

#app {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.7rem;
  height: 0.7rem;
}
::-webkit-scrollbar-track {
  background: #e6ebf0;
}
::-webkit-scrollbar-thumb {
  background-color: #aeb5bf;
  border-radius: 0.4rem;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
