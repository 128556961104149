<template>
  <button
    v-if="button"
    class="btn-transparent"
    @click="click">
    <i
      :class="{
        [iconClass]:true,
        [s.icon]:true,
        [s.tooltip]:tooltipe,
        [s.disabled]:disabled,
        [s.isAction]:isAction,
        [s.small]:small,
        [s.medium]:medium,
      }"
      :style="iconStyle" />
  </button>
  <i
    v-else
    :class="{
      [iconClass]:true,
      [s.icon]:true,
      [s.tooltip]:tooltipe,
      [s.disabled]:disabled,
      [s.isAction]:isAction,
      [s.small]:small,
      [s.medium]:medium,
    }"
    :style="iconStyle"
    @click="click" />
</template>

<script>
// import { Tooltip } from 'bq/directives/tooltip';
export default {
  name: 'BqIcon',
  // directives: {
  //   Tooltip,
  // },

  props: {
    name: {
      type: String,
      require: false,
      default: '',
    },
    tooltipe: {
      type: [String, Object],
      default: null,
    },
    tooltip: {
      type: [String, Object],
      default: null,
    },
    color: {
      type: String,
      default: '',
    },
    action: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      let icon = this.name || (this.$slots.default[0] ? this.$slots.default[0].text : '');
      if (!icon.includes('fas') && !icon.includes('far')) icon = 'fas ' + icon;
      return icon;
    },
    iconStyle() {
      return { ...this.colorIcon };
    },
    colorIcon() {
      return this.color ? { color: this.color } : {};
    },
    /**
     * Если к иконке привязано событие клика, то добавляет класс isAction
     *
     */
    isAction() {
      return this.$listeners.click !== undefined || this.action;
    },
  },
  methods: {
    click($e) {
      if (!this.disabled) {
        this.$emit($e.type, $e);
      }
    },
  },
};
</script>
<style lang="stylus" module="s">
.icon
  margin: 0 0.3rem
  color: $colors.grey.base;
  &.small
    font-size: 1.4rem !important
  &.medium
    font-size: 15px !important

.tooltip
  cursor: pointer
  transition: color 0.3s
  &:hover
    color: #647388
  &.disabled
    cursor: not-allowed
.isAction
  cursor: pointer
  color:$colors.grey.base
  transition: color 0.3s
  &:hover
    color: $colors.grey.lighten-1
  &.disabled
    cursor: not-allowed
    color: $colors.grey.lighten-1;
</style>
