export const authRouterNames = {
  AUTHORIZATION: 'Authorization',
  AUTHORIZATION_PUBLIC_AUTH: 'PublicAuth',
  AUTHORIZATION_USER_ACTIVATE: 'ActivateUser',
};

export const catalogRouteNames = {
  MAIN_CATALOG: 'MainCatalog',
  MAIN_CATALOG_LIST: 'MainCatalogList',
  MAIN_CATALOG_USER_PROFILE: 'UserProfile',
  MAIN_CATALOG_PROFILE_SETTINGS: 'ProfileSettings',
  MAIN_CATALOG_PROFILE_SUBSCRIPTIONS: 'ProfileSubscriptions',
  MAIN_CATALOG_PRODUCT_CARD: 'ProductCard',
  MAIN_CATALOG_BASKET_OF_GOODS: 'BasketOfGoods',
  MAIN_CATALOG_SIMPLE_PAGE: 'SimplePage',
};
