import title from '../setTitle.js';
import getBaseUrl from 'assets/utils/getBaseUrl';
import { catalogRouteNames } from '@/router/constants';
import mapRouterHooks from '@/router/RouterHooks';
const t = window.$translate.public_catalog_v2.app_components;

export default [
  {
    name: catalogRouteNames.MAIN_CATALOG,
    path: getBaseUrl(),
    redirect: { name: catalogRouteNames.MAIN_CATALOG_LIST },
    components: {
      default: () => import(/* webpackChunkName: "MainCatalog" */'@pages/MainCatalog'),
    },
    children: [
      {
        name: catalogRouteNames.MAIN_CATALOG_LIST,
        path: '',
        components: {
          default: () => import(/* webpackChunkName: "MainCatalogList" */'@pages/MainCatalog/Pages/ListCatalog'),
        },
        props: {
          default: (route) => ({
            isShowAuthModal: !!route.query.openAuthModal || false,
          }),
        },
      },
      {
        name: catalogRouteNames.MAIN_CATALOG_USER_PROFILE,
        path: 'profile',
        components: {
          default: () => import(/* webpackChunkName: "Profile" */'@pages/MainCatalog/Pages/Profile'),
        },
        redirect: { name: catalogRouteNames.MAIN_CATALOG_PROFILE_SETTINGS },
        beforeEnter(to, from, next) {
          mapRouterHooks.FOR_AUTHORIZED({ to, from, next });
        },
        children: [
          {
            name: catalogRouteNames.MAIN_CATALOG_PROFILE_SETTINGS,
            path: 'settings',
            meta: {
              title: title(t.profile_settings),
            },
            components: {
              default: () => import(/* webpackChunkName: "ProfileSettings" */ '@pages/MainCatalog/Pages/Profile/Components/tabs/Settings'),
            },
          },
          {
            name: catalogRouteNames.MAIN_CATALOG_PROFILE_SUBSCRIPTIONS,
            path: 'subscriptions',
            meta: {
              title: title(t.my_subscriptions),
            },
            components: {
              default: () => import(/* webpackChunkName: "ProfileSubscriptions" */ '@pages/MainCatalog/Pages/Profile/Components/tabs/Subscriptions'),
            },
          },
        ],
      },
      {
        name: catalogRouteNames.MAIN_CATALOG_PRODUCT_CARD,
        path: 'item/:id',
        props: { default: true },
        components: {
          default: () => import(/* webpackChunkName: "ProductCard" */'@pages/MainCatalog/Pages/ProductCard'),
        },
        meta: {
          title: title(t.product_card),
        },
      },
      // Модель корзины решили переименовать в "Избранное". Код оставил в покое, переводы поменял
      {
        name: catalogRouteNames.MAIN_CATALOG_BASKET_OF_GOODS,
        path: 'basket/:hash?',
        components: {
          default: () => import(/* webpackChunkName: "BasketOfGoods" */'@pages/MainCatalog/Pages/BasketOfGoods'),
        },
        meta: {
          title: title(t.favorite),
        },
      },
      {
        name: catalogRouteNames.MAIN_CATALOG_SIMPLE_PAGE,
        path: ':slug',
        components: {
          default: () => import(/* webpackChunkName: "SimplePage" */'@pages/MainCatalog/Pages/SimplePage'),
        },
      },
    ],
  },
];
