/**
 * Метод делает replace в роутере, при том сохраняет остальные параметры
 */
export default {
  methods: {
    setRouterQuery(queryObject, method = 'replace') {
      this.$router[method]({
        query: {
          ...this.$route.query,
          ...queryObject,
        },
      });
    },

    deleteQueryParam(key = '') {
      const query = { ...this.$route.query };
      delete query[key];
      this.$router.replace({ query });
    },
  },
};
