import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import cookie from 'js-cookie';
import authTypes from 'assets/utils/constants/authTypes';
import vars from 'assets/utils/vars';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state() {
      return {
        cookie: cookie.get(),
        windowWidth: 0,
        isAuthorized: needAuth(),
        mainSettings: vars.settings,
      };
    },

    mutations,

    actions,
  });
}

/**
 * функция проверяет, что авторизация для каталога отключена
 *
 * Если это так, то поле isAuthorized стора будет установлено в true.
 * Это необходимо, чтобы избежать дополнительных проверок в коде
 */
function needAuth() {
  return vars.settings.auth_type === authTypes.NO || vars.settings.is_private;
}
