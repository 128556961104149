/**
 * Миксин, который добавляет метод @name checkAuth для проверки авторизации пользователя
 */
import { mapState } from 'vuex';
import authTypes from 'assets/utils/constants/authTypes';
import cookie from 'js-cookie';
import initialVars from 'assets/utils/vars';

export default {
  computed: {
    ...mapState(['isAuthorized']),
    ...mapState('MainCatalog', ['authType']),
  },

  methods: {
    /**
     * Функция проверяет авторизован ли пользователь.
     * Если авторизован, то выполняет переданное действие.
     * Иначе открывает модальное окно авторизации.
     *
     * @param {Function} action - функция, для выполнения которой необходима проверка авторизации
     * @param {Array} args - аргументы для функции action
     * @param {String} actionType - тип действия
     */
    async checkAuth(action, args = [], actionType = 'default') {
      // действия связанные с экспортом ограничиваются и проверяются только если тип авторизации ON_ACTION
      // загрузку каталога проверяем только при необязательной регистрации
      if (this.isAuthorized ||
        (actionType === 'export' && this.authType === authTypes.VOLUNTARY) ||
        (actionType === 'load' && this.authType !== authTypes.REQUIRED)) {
        const res = await action.apply(this, args);
        if (res?.code === 401 || res?.code === 403) {
          cookie.remove(initialVars.cookie_name);
          this.$router.go(0);
        }
      } else if (this.authType !== authTypes.REQUIRED) {
        this.openModal('MainCatalog', {
          name: 'AuthorizationModal',
        });
      }
    },
  },
};
