<template>
  <button
    :class="{
      [$s.btn]:true,
      [$s[color]]:color,
      [$s.large]:large,
      [$s.disabled]: disabled,
    }"
    :type="type"
    :disabled="disabled">
    <i
      v-if="loading"
      :class="$s.loadingIcon"
      class="fas fa-spinner fa-spin" />
    <template v-else>
      <slot />
      <i
        v-if="iconName"
        :class="{
          [iconName]:true,
          [$s.iconWithText]:$slots.default
        }" />
    </template>
  </button>
</template>

<script>
/**
 * Компонент кнопки для упрощения и стандартизации работы и стилей для кнопок в пиме
 *
 * Параметры:
 * type - по-умолчанию тип кнопки button - стандартное значение добавлено из-за того что где-то в легаси коде все кнопки делаются по-умолчанию
 * с типом submit
 * color - один из стандартных цветов кнопки
 * small - сделать кнопку маленького размера
 * active - сделать вид кнопки активной
 * icon - кнопка является иконкой(т.е без текста) - добавляется специальный класс `btn-icon`
 * iconName - класс иконки после слота(если есть)
 * loading - при true заменяет весь контент на иконку лоадера
 *
 * Рекомендация:
 * Подключать глобально в новом функцилонале:
 * import Button from 'UI/Button';
 * Vue.component('b-button', Button);
 *
 * Подключение в уже существующем:
 *
 *
 * components: {
    'b-button': Button
  },
 * Пример
      <b-button @click.native="save">Сохранить</b-button>
 * Пример как иконки
      <b-button
          icon
          small
          :icon-name="infomodelLoaded ? 'fas fa-angle-up' : 'fas fa-angle-right'"
          @click.native="loadInfomodel" />
 */
export default {
  name: 'UIButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: '',
      validation: (val) => ['', 'green', 'blue', 'red', 'white-red', 'black', 'white', 'grey', 'greyBlue', 'lightGreyBlue', 'darkBlue'].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    // active: {
    //   type: Boolean,
    //   default: false,
    // },
    /**
     * Является ли кнопка иконкой
     */
    icon: {
      type: Boolean,
      default: false,
    },
    /**
     * Название иконки(класс в fontawesome)
     */
    iconName: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" module="$s">
$blue = #2EA2F8
$blueDisabled = #68bcfa
$dark = #292929
$darkDisabled = #535353
$red = #F95359
$redDisabled = #F95359
$darkBlue = #2F61BF
$disabledGray = #A0ADB9

.btn
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid transparent;
  cursor: pointer;
  color:#fff
  transition: background 0.3s
  border-radius: 3px;
  &.large
    font-weight: 500;
    line-height: 2rem;
    font-size: 1.2rem;
    padding: 0.7rem 1rem
    .loadingIcon
      font-size: 1.4rem
      margin: 3px;

  &.blue
    background:$blue
    &:not([disabled])
      &:hover
        background $blue*1.1
    &[disabled]
      background $blueDisabled
  &.darkBlue
    background-color $darkBlue
    &:not([disabled])
      &:hover
        background $darkBlue*1.1
    &[disabled]
      background rgba($disabledGray, .5)
      cursor auto
  &.dark
    background:$dark
    &:not([disabled])
      &:hover
        background $dark*1.7
    &[disabled]
      background $darkDisabled
  &.white
    border-color: #C1C1C1;
    background: #fff;
    color:#292929
    &:not([disabled])
      &:hover
        background: #fff;
  &.grey
    border-color: #5b5b5b;
    background: #fff;
    color:#5b5b5b
    &:not([disabled])
      &:hover
        background: #eeeeee;
  &.lightGreyBlue
    border-color: #5b5b5b;
    background: #fff;
    color:#5b5b5b
    &:not([disabled])
      &:hover
        background: #2EA2F8;
        border-color: #2EA2F8;
        color: #fff;
        @media screen and (max-width: 550px ), screen and (min-width: 900px ) and (max-width: 1200px )
          background-color #fff
          color #2EA2F8
  &.greyBlue
    border-color: #5b5b5b;
    background: #fff;
    color:#5b5b5b
    &:not([disabled])
      &:hover
        background: #2EA2F8;
        border-color: #2EA2F8;
        color: #fff;
  &.red
    background:$red
    &:not([disabled])
      &:hover
        background $red*1.2
    &[disabled]
      background $redDisabled
  &.white-red
    border-color #F95359
    background: #fff;
    color: #F95359
    &:not([disabled])
      &:hover
        background #F95359
        color: #fff;

  &:focus
    outline: none;
    // outline: 5px auto -webkit-focus-ring-color;
  &:not([disabled])
    &:hover
      background: linear-gradient(0deg, #eaeef3 0%, #fff 100%);
.disabled
  opacity 0.5
  cursor not-allowed

.iconWithText
  margin-left 5px

</style>
