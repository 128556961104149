import 'assets/utils/setPublicPath';
import 'assets/styl/vuetifySimple/main.styl';
import 'assets/styl/base/index.styl';
import '@babel/polyfill';
import Vue from 'vue';
import 'element-closest-polyfill';
import { globalComponents } from './globalComponents';
import App from './App.vue';
import { createRouter } from './router';
import { createStore } from './store';
import { sync } from 'vuex-router-sync';

globalComponents(Vue);

Vue.config.productionTip = false;

const router = createRouter();
const store = createStore();
sync(store, router);

const app = new Vue({
  router,
  store,
  render: h => h(App),
});
app.$mount('#main');
