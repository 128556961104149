<template>
  <section
    v-if="value"
    :class="modal.wrapper"
    :data-uid="_uid">
    <transition name="fade">
      <div
        v-show="showContainer"
        :id="`modal${_uid}`"
        :class="{[modal.scroll]: overflowAuto, [modal.container]: true}"
        :style="{'width': `${width}px`}">
        <header
          v-if="!noHeader"
          :class="modal.mHeader">
          <h1 :class="modal.mTitle">
            {{ title }}
          </h1>
          <button
            v-if="closable"
            class="btn-transparent"
            :class="modal.closeIcon"
            @click="hide">
            <img
              src="./Assets/closeIcon.svg">
          </button>
        </header>
        <hr
          v-if="!noHeader"
          class="mx-3 mb-3">

        <div :class="[modal.mBody, bodyClass]">
          <slot name="body" />
        </div>

        <footer
          v-if="$slots.footer"
          :class="[modal.mFooter, footerClass]">
          <slot name="footer" />
        </footer>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 570,
    },
    unsaved: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    overflowAuto: {
      type: Boolean,
      default: true,
    },
    /**
     * Добавляет класс к слоту body
     */
    bodyClass: {
      type: String,
      default: null,
    },
    /**
     * Добавляет класс к слоту footer
     */
    footerClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      needClose: false,
      showContainer: false,
    };
  },
  created() {
    if (this.closable) {
      document.body.addEventListener('mousedown', this.onMousedown);
      document.body.addEventListener('mouseup', this.onMouseup);
    }
  },
  mounted() {
    this.showContainer = true;
    // грязно
    this.addClosestPolyfill();
  },
  beforeDestroy() {
    this.showContainer = false;

    if (this.closable) {
      document.body.removeEventListener('mousedown', this.onMousedown);
      document.body.removeEventListener('mouseup', this.onMouseup);
    }
  },
  methods: {
    hide() {
      let result = true;
      if (this.unsaved) {
        result = confirm(window.$translate.common.unsavedContinue);
      }
      if (result) {
        this.$emit('input', false);
      }
    },
    show() {
      this.$emit('input', true);
    },

    onMouseup({ target, button }) {
      let clickNotInModal = target.closest(`#modal${this._uid}`) === null;
      // при двоиных модалках нужно слушать клик только на своей области
      // WARNING: двойная модалка не проверялась 12.11.19
      let mouseupMyInArea = +target.dataset.uid === this._uid;
      if (clickNotInModal && mouseupMyInArea && button === 0 && this.needClose) {
        this.hide();
      }
    },
    onMousedown({ target, button }) {
    // button=0 - клик левой кнопкой мыши
      if (button === 0) {
        if (target.closest('[id^="modal"]') === null) {
          this.needClose = true;
        } else {
          this.needClose = false;
        }
      }
    },
    addClosestPolyfill() {
      // проверяем поддержку
      if (!Element.prototype.closest) {
        // реализуем
        Element.prototype.closest = function (css) {
          var node = this;

          while (node) {
            if (node.matches(css)) return node;
            else node = node.parentElement;
          }
          return null;
        };
      }
    },
  },
};
</script>

<style lang="stylus" module="modal">
.scroll
  overflow-y auto
.wrapper
  display flex
  padding: 20px
  align-items center
  overflow: auto
  justify-content center
  width 100vw
  height 100vh
  position fixed
  top 0
  left 0
  background-color rgba(53, 60, 73, .6)
  z-index 9999

.container
  display flex
  flex-direction column
  max-height 100%
  background-color #fff
  border-radius 0.4rem
  box-shadow: 0 0.5rem 1.5rem -0.5rem rgba(0,0,0,0.5);

.mHeader
  display: flex;
  position relative
  padding .3rem .66rem .3rem 1.9rem
  .mTitle
    color: #292929;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    flex-grow: 1;
.mBody
  padding 0 1.2rem 1.6rem
  height 100%
  overflow-y auto
.mFooter
  display flex
  align-items center
  flex-shrink 0
  flex-basis 5rem
  padding 1.1rem 1.4rem
  background-color #F8F8F8
  border-top #ECECEC 1px solid
  border-radius 0 0 0.4rem 0.4rem
  button:not(:last-child)
    margin-right .8rem

</style>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s, transform .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0.5;
  transform: translateY(-10px);
}
</style>
