import urls from './urls';
import getDataHelper from 'assets/utils/getDataHelper';
import { registrationUserAxiosConfig } from 'assets/utils/getDataHelper/axiosInstanses';
const GET_DATA = getDataHelper(null, urls);
const GET_DATA_REGISTRATION_USERS = getDataHelper(registrationUserAxiosConfig(), urls);

export default {
  async ACCOUNT_LOGIN({ state, commit }, data) {
    const res = await GET_DATA({ url: 'login' }, 'post', data);
    return res;
  },

  async REGISTER_USER({ state, commit, rootState }, data) {
    const res = await GET_DATA_REGISTRATION_USERS({ url: 'registerUser' }, 'post', data);
    return res;
  },

  async ACCOUNT_LOGOUT({ state, commit }) {
    const res = await GET_DATA({ url: 'logout' }, 'post');
    return res;
  },

  async RESTORE_PASSWORD({ state, commit }, data) {
    const res = await GET_DATA_REGISTRATION_USERS({ url: 'restore_password' }, 'post', data);
    return res;
  },

  async FETCH_SETTINGS() {
    const res = await GET_DATA({ url: 'settings' });
    return res;
  },
};
