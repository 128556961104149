import vars from 'assets/utils/vars';

export default function () {
  return {
    modals: [],
    authType: vars.settings.auth_type,
    simplePageData: {},
    simplePageRequest: {},
    filtersValues: {},
    keyProgress: 0,
  };
}
