<template>
  <transition
    name="fade"
    mode="out-in"
    @enter="cb('enter',$event)">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'DefaultTransition',
  methods: {
    cb(name, $e) {
      this.$emit(name, $e);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
