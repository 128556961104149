import { catalogRouteNames } from '@/router/constants';
import { getNavigationGuard } from '@/router/RouterHooks/bindNavigation';
import { hasAuthCookie, isRequiredAuth, isAuthNotNeeded } from '@/router/RouterHooks/constants';

let navigationGuard = {};

const usePublicAuth = () => {
  navigationGuard = getNavigationGuard();
  const isNeededAuthorization = isRequiredAuth && !hasAuthCookie();
  isNeededAuthorization ? navigationGuard.routeNext() : navigationGuard.routeNext(getGoTo());
};

const getGoTo = () => navigationGuard.routeTo.query.to ? navigationGuard.routeTo.query.to : {
  name: catalogRouteNames.MAIN_CATALOG_LIST,
  query: { openAuthModal: !hasAuthCookie() && !isAuthNotNeeded },
};

export default usePublicAuth;
