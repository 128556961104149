import cookie from 'js-cookie';
import initialVars from 'assets/utils/vars';

export default {
  SET_WINDOW_WIDTH(state, windowWidth) {
    state.windowWidth = windowWidth;
  },

  SET_COOKIE(state) {
    state.cookie = cookie.get();
  },

  SET_AUTHORIZED_STATUS(state) {
    state.isAuthorized = !!cookie.get(initialVars.cookie_name);
  },

  SET_COUNT_BASKET(state, count) {
    state.mainSettings.cart_total = count;
  },
};
