export default {
  methods: {
    openModal(module, data) {
      let pathArr = module.split('/');
      let stateOfModule = this.$store.state;
      pathArr.forEach(i => {
        stateOfModule = stateOfModule[i];
      });
      stateOfModule.modals.push(data);
    },
  },
};
